import { useParams } from "react-router-dom";
import products from "../../products.json";
import "./product.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import Topbar from "../../components/topbar/Topbar";

export default function Product(){
    const { productID } = useParams();

    const productData = products.products.filter(p => p.id === productID)[0];

    return (
        <div className="page-wrapper">
            <Topbar />
            <div id="product-page">
                <div className="product__image-wrapper card">
                    <img className="product__image" src={`/pictures/${productData.image}`}></img>
                </div>
                <div className="product__name card">
                    {productData.name}
                </div>
                <div className="product__price">
                    <div className="product__price__cash">
                        <div className="card" style={{padding: 0, display: 'grid', placeItems: 'center'}}>
                            <FontAwesomeIcon icon={faMoneyBill} className="card" />
                        </div>
                        <div className="product__price__cash__price card">
                            {`$${productData.priceCash}`}
                        </div>
                    </div>
                    <div className="product__price__card">
                    <div className="card" style={{padding: 0, display: 'grid', placeItems: 'center'}}>
                            <FontAwesomeIcon icon={faCreditCard} className="card" />
                        </div>
                        <div className="product__price__card__price card">
                            {`$${productData.priceCard}`}
                        </div>
                        <div className="product__price__card__installments card">
                            {`${productData.installments} cuotas`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
