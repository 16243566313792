import "./topbar.css";

export default function Topbar(){
    return (
        <div className="topbar">
            <div className="topbar__logo">
                ÁLAMO
            </div>
            <div className="topbar__subtitle">
                Tu casa, más linda
            </div>
        </div>
    );
}
